/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        var screenSize = 'lg';

        function checkSize() {
          if($('.test-size').css('font-size') === '1px') {
            screenSize = 'xs';
          } else if($('.test-size').css('font-size') === '2px') {
            screenSize = 'sm';
          } else if($('.test-size').css('font-size') === '3px') {
            screenSize = 'md';
          } else if($('.test-size').css('font-size') === '4px') {
            screenSize = 'lg';
          } else if($('.test-size').css('font-size') === '5px') {
            screenSize = 'xl';
          }
        }

        function recaptchaResize() {
          if($('.ginput_recaptcha').length) {
            if (screenSize === 'xs') {
              $(".ginput_recaptcha").css({
                "transform": "scale(0.75)",
                "-webkit-transform": "scale(0.75)",
                "transform-origin": "0 0",
                "-webkit-transform-origin": "0 0"
              });
            } else if (screenSize === 'sm') {
              $(".ginput_recaptcha").css({
                "transform": "scale(0.9)",
                "-webkit-transform": "scale(0.9)",
                "transform-origin": "0 0",
                "-webkit-transform-origin": "0 0"
              });
            } else {
              $(".ginput_recaptcha").css({
                "transform": "scale(1)",
                "-webkit-transform": "scale(1)",
                "transform-origin": "0 0",
                "-webkit-transform-origin": "0 0"
              });
            }
          }
        }

        checkSize();
        recaptchaResize();

        $(window).resize(function() {
          checkSize();
          recaptchaResize();
        });

        // toggles hamburger and nav open and closed states
        var removeClass = true;
        $(".hamburger").click(function () {
          $(".hamburger").toggleClass('is-active');
          $("#mobileNav").toggleClass('mobileNav-active');
          $(".mobileNavBody").toggleClass('mobileNavBody-active');
          $(window).scrollTop(0);
          removeClass = false;
        });

        $(".mobileNav").find('.nav').click(function() {
          removeClass = false;
        });

        $("html").click(function () {
          if (removeClass) {
            $(".hamburger").removeClass('is-active');
            $("#mobileNav").removeClass('mobileNav-active');
            $(".mobileNavBody").removeClass('mobileNavBody-active');
          }
          removeClass = true;
        });

        var fixed = document.getElementById('mobileNav');

        fixed.addEventListener('touchmove', function(e) {
          e.preventDefault();
        }, false);

        var fixedHamburger = document.getElementById('hamburger');

        fixedHamburger.addEventListener('touchmove', function(e) {
          e.preventDefault();
        }, false);


        $(window).scroll(function () {
          if ($(this).scrollTop() > 100) {
            $('.scrollup').fadeIn();
          } else {
            $('.scrollup').fadeOut();
          }
        });

        $('.scrollup').click(function () {
          $("html, body").animate({
            scrollTop: 0
          }, 600);
          return false;
        });

        $(function() {
          $('a[href*="#"]:not([href="#"]).id-link').click(function() {
            if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
              var target = $(this.hash);
              target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
              if (target.length) {
                $('html, body').animate({
                  scrollTop: target.offset().top
                }, 1000);
                return false;
              }
            }
          });
        });


        function initialize_gmap(point_lat, point_long) {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          var isDraggable = w > 480 ? true : false;
          var point = new google.maps.LatLng(point_lat, point_long);
          var map_lat = point_lat + 0.2;
          var map_long = point_long - 0.3;

          var settings = {
            zoom: 6,
            center: {lat: map_lat, lng: map_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: false,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: false,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP};

          var map = new google.maps.Map(document.getElementById("footer_map_canvas"), settings);

          // Add the circle for this city to the map.
          var serviceArea = new google.maps.Circle({
            strokeColor: '#2F62AB',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#2F62AB',
            fillOpacity: 0.35,
            map: map,
            center: {lat: point_lat, lng: point_long},
            radius: 98186.9
          });

          var companyMarker = new google.maps.Marker({
            position: point,
            map: map
          });

          google.maps.event.addDomListener(window, "resize", function() {
             var center = map.getCenter();
             google.maps.event.trigger(map, "resize");
             map.setCenter(center);
          });
        }

        if($('#footer_map_canvas').length) {
          var point_lat = parseFloat($('#footer_map_canvas').siblings('.map-data').find('.point_lat').data('pointlat'));
          var point_long = parseFloat($('#footer_map_canvas').siblings('.map-data').find('.point_long').data('pointlong'));
          initialize_gmap(point_lat, point_long);
        }



        $(".no-click-link").click(function(e) {
            e.preventDefault();
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.vacation-rental h4, .vacation-rental p').widowFix();

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Vacation Rentals page
    'vacation_rentals': {
      init: function() {
        // JavaScript to be fired on the home page
        $('.vacation-rental h4, .vacation-rental p').widowFix();

      }
    },
    // Contact page
    'contact': {
      init: function() {
        // JavaScript to be fired on the about us page
        function initialize_gmap(point_lat, point_long, address, title, directions) {
          var w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);

          var isDraggable = w > 480 ? true : false;
          var point = new google.maps.LatLng(point_lat, point_long);
          var map_lat = point_lat - 0.5;
          var map_long = point_long;

          var settings = {
            zoom: 7,
            center: {lat: map_lat, lng: map_long},
            scrollwheel: false,
            draggable: isDraggable,
            mapTypeControl: true,
            mapTypeControlOptions: {style: google.maps.MapTypeControlStyle.DROPDOWN_MENU},
            navigationControl: true,
            navigationControlOptions: {style: google.maps.NavigationControlStyle.SMALL},
            mapTypeId: google.maps.MapTypeId.ROADMAP};

          var map = new google.maps.Map(document.getElementById("contact_map_canvas"), settings);

          var contentString = '<div id="gmapContent" style="text-align:left;">'+
            '<a href="' + directions + '" target="_blank"><h3>' + title + '<br><small>(Click for directions)</small></h3></a>'+
            '<div id="bodyContent">'+
            '<p>' + address + '</p>'+
            '</div>'+
            '</div>';

          // Add the circle for this city to the map.
          var serviceArea = new google.maps.Circle({
            strokeColor: '#2F62AB',
            strokeOpacity: 0.5,
            strokeWeight: 1,
            fillColor: '#2F62AB',
            fillOpacity: 0.35,
            map: map,
            center: {lat: point_lat, lng: point_long},
            radius: 98186.9
          });

          var infowindow = new google.maps.InfoWindow({
            content: contentString
          });

          var companyMarker = new google.maps.Marker({
            position: point,
            map: map,
            title: title
          });

          infowindow.open(map,companyMarker);

          google.maps.event.addListener(companyMarker, 'click', function() {
            infowindow.open(map,companyMarker);
          });

          google.maps.event.addDomListener(window, "resize", function() {
             var center = map.getCenter();
             google.maps.event.trigger(map, "resize");
             map.setCenter(center);
          });
        }

        if($('#contact_map_canvas').length) {
          var point_lat = parseFloat($('#contact_map_canvas').siblings('.map-data').find('.point_lat').data('pointlat'));
          var point_long = parseFloat($('#contact_map_canvas').siblings('.map-data').find('.point_long').data('pointlong'));
          var address = $('#contact_map_canvas').siblings('.map-data').find('.address').data('address');
          var title = $('#contact_map_canvas').siblings('.map-data').find('.title').data('title');
          var directions = $('#contact_map_canvas').siblings('.map-data').find('.directions').data('directions');
          initialize_gmap(point_lat, point_long, address, title, directions);
        }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
